
export default [
  {
    path: '/customer/index',
    redirect: () => {
      return { path: `/redirect`, query: { url: 'https://cateno.servicefirst.no/customers' } }
    }
  },
  {
    path: '/customer/detail/:id',
    redirect: (to) => {
      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/customer/${to.params.id}` } }
    }
  },
  {
    path: '/project/index',
    redirect: () => {
      return { path: `/redirect`, query: { url: 'https://cateno.servicefirst.no/projects' } }
    }
  },
  {
    path: '/project/detail/:id',
    redirect: (to) => {
      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/project/${to.params.id}` } }
    }
  },
  {
    path: '/ticket/detail/:id',
    redirect: (to) => {
      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/ticket/${to.params.id}` } }
    }
  },
  {
    path: '/ticket/index', redirect: to => {
      if (to.query.trash)
        return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/tickets/trash` } }

      if (to.query.tt == 'ct')
        return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/tickets/et` } }

      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/tickets/pt` } }
    }
  },
  {
    path: '/attachments/:id/:alias/:dataid/:filename',
    redirect: (to) => {
      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/ticket/attach/${to.params.dataid}/${to.params.filename}` } }
    }
  },
  {
    path: '/commentattach/:id/:alias/:dataid/:filename',
    redirect: (to) => {
      return { path: `/redirect`, query: { url: `https://cateno.servicefirst.no/comment/attach/${to.params.dataid}/${to.params.filename}` } }
    }
  },
];