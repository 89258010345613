import store from "@/state/store";

export default [
  {
    path: "/change-password",
    name: "changePassword",
    meta: { title: "Change password", authRequired: true },
    component: () => import("../views/staff/change-password.vue"),
  },
  {
    path: "/clients",
    name: "clientList",
    meta: { title: "Clients", authRequired: true },
    component: () => import("../views/clients/list.vue"),
  },
  {
    path: "/client/:id",
    name: "clientDetail",
    meta: { title: "View client", authRequired: true },
    component: () => import("../views/clients/detail.vue"),
  },
  {
    path: "/client/add",
    name: "clientAdd",
    meta: { title: "Add client", authRequired: true },
    component: () => import("../views/clients/edit.vue"),
  },
  {
    path: "/client/edit/:id",
    name: "clientEdit",
    meta: { title: "Edit client", authRequired: true },
    component: () => import("../views/clients/edit.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: { title: "Users", authRequired: true },
    component: () => import("../views/users/list.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    meta: { title: "FAQs", authRequired: true },
    component: () => import("../views/faqs/list.vue"),
  },
  {
    path: "/faq/add",
    name: "faqAdd",
    meta: { title: "Add FAQ", authRequired: true },
    component: () => import("../views/faqs/edit.vue"),
  },
  {
    path: "/faq/edit/:id",
    name: "faqEdit",
    meta: { title: "Edit FAQ", authRequired: true },
    component: () => import("../views/faqs/edit.vue"),
  },
  {
    path: "/faqs/section",
    name: "faqsSection",
    meta: { title: "FAQs section", authRequired: true },
    component: () => import("../views/faqs-section/list.vue"),
  },
  {
    path: "/marketplaces",
    name: "marketplaces",
    meta: { title: "Marketplaces", authRequired: true },
    component: () => import("../views/marketplaces/list.vue"),
  },
  {
    path: "/marketplace/add",
    name: "marketplaceAdd",
    meta: { title: "Add item", authRequired: true },
    component: () => import("../views/marketplaces/edit.vue"),
  },
  {
    path: "/marketplace/edit/:id",
    name: "marketplaceEdit",
    meta: { title: "Edit item", authRequired: true },
    component: () => import("../views/marketplaces/edit.vue"),
  },
  {
    path: "/user/add",
    name: "addUser",
    meta: { title: "Add user", authRequired: true },
    component: () => import("../views/users/edit.vue"),
  },
  {
    path: "/user/edit/:id",
    name: "editUser",
    meta: { title: "Edit user", authRequired: true },
    component: () => import("../views/users/edit.vue"),
  },
  {
    path: "/settings/packages",
    name: "packagesSettings",
    meta: { title: "Packages setting", authRequired: true },
    component: () => import("../views/settings/packages/index.vue"),
  },
  {
    path: "/settings/general",
    name: "generalSettings",
    meta: { title: "General settings", authRequired: true },
    component: () => import("../views/settings/general-settings.vue"),
  },
  {
    path: "/settings/job-settings",
    name: "jobSettings",
    meta: { title: "Job settings", authRequired: true },
    component: () => import("../views/settings/job-settings/index.vue"),
  },
  {
    path: "/settings/email-template",
    name: "emailTemplate",
    meta: { title: "Email template", authRequired: true },
    component: () => import("../views/settings/email-template/index.vue"),
  },
  {
    path: "/settings/email-queues",
    name: "emailQueues",
    meta: { title: "Email queues", tkey: "email-queue-page-title", authRequired: true },
    component: () => import("../views/settings/email-queues/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: { title: "Forgot Password", authRequired: false }
  },
  {
    path: "/reset-password/:token",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: { title: "Reset Password", authRequired: false }
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout")
  },
  {
    path: '/auth/lockscreen',
    name: 'lockscreen',
    meta: {
      title: "Lock Screen", authRequired: false,
    },
    component: () => import('../views/auth/lockscreen')
  },
  {
    path: '/error/500',
    name: '500',
    meta: {
      title: "Internal Error", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/error/404',
    name: '404-not-found',
    meta: {
      title: "Not Found", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/offline',
    name: 'offline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/offline')
  },
  {
    path: '/auth/success-msg',
    name: 'success-msg',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg')
  },
  {
    path: "/redirect",
    name: "redirect",
    meta: { authRequired: false },
    component: () => import("../views/account/redirect")
  }
];