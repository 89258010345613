import axios from 'axios';
import { adminService } from "../services/admin.service"
// import { useRouter } from 'vue-router'

export function JwtInterceptor() {

    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url

        const jwt = adminService.getJwt()
        if (jwt)
            request.headers["Authorization"] = `Bearer ${jwt}`;

        return request;
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {

        if (error.response.status == 401) {
            // adminService.logout()
            // const router = useRouter()
            // router.push({ path: '/login' });
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });     
}